.react-tags {
  border-radius: 0.25rem;
  border: 1px solid #cbd5e0; /* gray-400 */
  color: #718096; /* gray-600 */
  cursor: text;
  padding: 0.75rem 0.75rem 0;
  position: relative;
}

.react-tags *:focus {
  box-shadow: none !important;
}

.react-tags.is-focused {
  outline: none;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  background-color: #f7fafc; /* gray-100 */
  border-radius: 0.25rem;
  border: 1px solid #cbd5e0; /* gray-400 */
  display: inline-block;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  padding: 4px 8px;
}

.react-tags__selected-tag:after {
  color: #a0aec0; /* gray-500 */
  content: '\2715';
  font-size: 12px;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #fc8181; /* red-400 */
}
.react-tags__selected-tag:hover:after,
.react-tags__selected-tag:focus:after {
  color: #fc8181; /* gray-400 */
}

.react-tags__search {
  display: inline-block;
  max-width: 100%;
  padding: 7px 2px;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    position: relative;
  }
}

.react-tags__search-input {
  max-width: 100%;
  min-width: 100px;
  padding-bottom: 0.75rem;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  background-color: #fff; /* white */
  border-radius: 0.25rem;
  border: 1px solid #cbd5e0; /* gray-400 */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin: 4px 0;
}

.react-tags__suggestions li {
  border-bottom: 1px solid #edf2f7; /* gray-200 */
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
}

.react-tags__suggestions li mark {
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  background: #f7fafc; /* gray-100 */
}

.react-tags__suggestions li.is-active {
  background: #f7fafc; /* gray-100 */
}

.react-tags__suggestions li.is-disabled {
  cursor: auto;
  opacity: 0.5;
}
