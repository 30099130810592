.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  border: 1px solid #cbd5e0; /* gray-400 */
  border-radius: 0.25rem;
  padding: 0.75rem;
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  background-color: #fff; /* gray-400 */
  border-radius: 0 0 0.25rem 0.25rem;
  border: 1px solid #cbd5e0; /* gray-400 */
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  margin-top: -6px;
  max-height: 300px;
  overflow-x: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #edf2f7; /* gray-200 */
}
