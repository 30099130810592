.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.DateRangePickerInput__withBorder {
  border-color: #cbd5e0; /* gray-400 */
  border-radius: 0.25rem;
}

.DateInput {
  background-color: transparent;
  width: auto;
}

.DateInput_input {
  background-color: transparent;
  border: none;
  line-height: inherit;
  font-size: 16px;
  padding: 0.75rem;
  text-align: center;
}
.DateInput_input__focused {
  border-bottom: none;
}

.DateRangePickerInput_arrow {
  color: #a0aec0; /* gray-500 */
  margin-left: 8px;
  margin-right: 8px;
  width: 20px;
}

.CalendarDay:focus {
  box-shadow: none !important;
}

.CalendarDay__selected_span {
  background-color: rgba(162, 153, 234, 0.75); /* purple */
  border-color: rgba(162, 153, 234, 0.75); /* purple */
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #a299ea; /* purple */
  border-color: #a299ea; /* purple */
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #a299ea; /* purple */
  border-color: #a299ea; /* purple */
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: rgba(162, 153, 234, 0.75); /* purple */
  border-color: rgba(162, 153, 234, 0.75); /* purple */
  color: #fff; /* white */
}
.CalendarDay__hovered_span:active {
  background-color: #a299ea; /* purple */
  border-color: #a299ea; /* purple */
}

.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right-color: #a299ea; /* purple */
}

.DateRangePicker_picker__portal {
  z-index: 20;
}
